import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './switchDeviceTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const switchDeviceSlice = createSlice({
  name: 'switchDevice',
  initialState,
  reducers: {
    setSwitchDevice(switchDeviceState, action: PayloadAction<IState>) {
      switchDeviceState.state = { ...action.payload };
    },
    updateSwitchDevice(
      switchDeviceState,
      action: PayloadAction<Partial<IState>>
    ) {
      switchDeviceState.state = {
        ...switchDeviceState.state,
        ...action.payload,
      };
    },
    clear(switchDeviceState) {
      switchDeviceState.state = { ...getInitialState().state };
    },
  },
});

export const selectSwitchDevice = (switchDeviceState: {
  switchDevice: { state: IState };
}) => switchDeviceState.switchDevice.state;

export const { setSwitchDevice, updateSwitchDevice, clear } =
  switchDeviceSlice.actions;

export default switchDeviceSlice.reducer;
