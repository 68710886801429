import {
  selectConfiguration,
  useAppSelector,
  useSendConsultSwitchMobileMutation,
  useSendAjoutEvenementSwitchMobileMutation,
  selectSwitchDevice,
  useAppDispatch,
  updateSwitchDevice,
} from '@store';
import { useEffect, useState } from 'react';
import { IState } from 'store/configuration/configurationTypes';
import { IEvenementSwitchMobile } from '@store';
import { FINAL_EVENTS_SWITCH_DEVICE, typeAppareil } from '@types';
import useParcoursType from './useParcoursType';
import usePersistingState from './usePersistingState';
import dayjs, { Dayjs } from 'dayjs';

export interface IListenerSwitchMobile {
  sendAddEventSwitchMobile: (evenement: string) => void;
  triggerListenerSwitchMobile: () => void;
  responseListenerSwitchMobileConsult: IEvenementSwitchMobile[] | null;
  interrupteListener: () => void;
  responseSendAjoutSwitchDevice: any;
}

interface request {
  numero_souscription: string;
  body: {
    typeAppareil: typeAppareil;
    idAppareil: string;
    evenementRealise?: string;
    dateRecuperation?: string;
  };
}

const buildListenerRequest = ({
  config,
  isParcoursSwitchQRCode,
  lastEventDate,
  eventToAdd,
}: {
  config: IState;
  isParcoursSwitchQRCode: boolean;
  lastEventDate?: string;
  eventToAdd?: string;
}): any => {
  const result: request = {
    numero_souscription: config?.souscriptionId ?? '',
    body: {
      typeAppareil: isParcoursSwitchQRCode
        ? typeAppareil.MOBILE
        : typeAppareil.ORDINATEUR,
      idAppareil: config?.idAppareil ?? '',
      evenementRealise: eventToAdd,
      dateRecuperation: lastEventDate,
    },
  };
  return result;
};

const useListenerSwitchMobile = (): IListenerSwitchMobile => {
  const [triggerSwitchMobileConsult, resultSwitchConsult] =
    useSendConsultSwitchMobileMutation();
  const [sendAjoutEvenementSwitchMobileMutation, responseListenerSwitchMobile] =
    useSendAjoutEvenementSwitchMobileMutation();
  const config = useAppSelector(selectConfiguration);
  const { isParcoursNominal } = useParcoursType();
  const [responseSwitchDeviceConsult, setResponseSwitchDeviceConsult] =
    useState<IEvenementSwitchMobile[] | null>(null);
  const [isListenerInterrupted, setIsListenerInterrupted] = useState(false);
  const { isListenerTriggering } = useAppSelector(selectSwitchDevice);
  const dispatch = useAppDispatch();

  const [lastEventDate, setLastEventDate, getLastEventDate] =
    usePersistingState<string | null>('listener-lastEventDate', null);

  const isParcoursSwitchQRCode = !isParcoursNominal();

  const triggerListener = () => {
    setIsListenerTriggering(true);
    triggerSwitchMobileConsult(
      buildListenerRequest({
        config: config,
        isParcoursSwitchQRCode: isParcoursSwitchQRCode,
        lastEventDate: getLastEventDate() ?? undefined,
      })
    );
  };

  const interrupteListener = () => {
    setIsListenerInterrupted(true);
  };

  const setIsListenerTriggering = (isListenerTriggering: boolean) => {
    dispatch(
      updateSwitchDevice({
        isListenerTriggering: isListenerTriggering,
      })
    );
  };

  useEffect(() => {
    if (isListenerInterrupted) {
      setIsListenerInterrupted(false);
      setIsListenerTriggering(false);
      return;
    }
    if (
      ((resultSwitchConsult?.error as { data: { error: string }[] })?.data ??
        [])[0]?.error === 'RECUPERATION_SWITCH_DEVICE_TIMEOUT'
    ) {
      triggerListener();
      return;
    }
    if (resultSwitchConsult?.data && resultSwitchConsult?.isSuccess) {
      setResponseSwitchDeviceConsult(resultSwitchConsult?.data);
      resultSwitchConsult?.data.forEach((e) => {
        const lastEvent = !!getLastEventDate()
          ? dayjs(getLastEventDate())
          : null;
        if (lastEvent === null || lastEvent?.isBefore(dayjs(e.dateEvenement))) {
          setLastEventDate(e.dateEvenement);
        }

        if (!FINAL_EVENTS_SWITCH_DEVICE.includes(e.evenementRealise)) {
          triggerListener();
        } else {
          setLastEventDate(null);
        }
      });
    }
  }, [resultSwitchConsult]);

  const ajoutEvent = (evenement: string) => {
    if (FINAL_EVENTS_SWITCH_DEVICE.includes(evenement)) {
      setLastEventDate(null);
      setIsListenerInterrupted(true);
    }
    sendAjoutEvenementSwitchMobileMutation(
      buildListenerRequest({
        config,
        isParcoursSwitchQRCode,
        eventToAdd: evenement,
      })
    );
  };

  return {
    sendAddEventSwitchMobile: ajoutEvent,
    triggerListenerSwitchMobile: () => {
      if (isListenerTriggering || isListenerInterrupted) {
        return;
      }
      triggerListener();
    },
    responseListenerSwitchMobileConsult: responseSwitchDeviceConsult,
    interrupteListener: interrupteListener,
    responseSendAjoutSwitchDevice: responseListenerSwitchMobile,
  };
};

export default useListenerSwitchMobile;
